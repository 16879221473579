
import { RoutesMixin, TranslationMixin } from '~/mixins'

export default {
  name: 'SectionCardList',
  props: {
    item: {
      type: Object,
      required: true,
      call_to_action_link: {
        type: Object,
        id: Number
      },
      teaser_items: {
        type: Array,
        required: true,
        teaser_items_id: {
          type: Object,
          required: true,
          translations: {
            type: Array,
            required: true
          }
        }
      }
    },
    translation: {
      type: Object,
      required: false,
      call_to_action_label: {
        type: String
      },
      headline: {
        type: String,
        required: true
      },
      text: {
        type: String
      }
    }
  },
  mixins: [RoutesMixin, TranslationMixin],
  computed: {
    pagePath() {
      return this.pathForPage(this.item.call_to_action_link)
    }
  }
}
