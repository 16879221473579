import { render, staticRenderFns } from "./card--wave.vue?vue&type=template&id=540b424f&lang=pug&"
import script from "./card--wave.vue?vue&type=script&lang=js&"
export * from "./card--wave.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppImage: require('/co2unt/services/customer-app/components/app/app__image/app__image.vue').default,CardWaveGenerator: require('/co2unt/services/customer-app/components/card/card--wave/card--wave-generator.vue').default})
