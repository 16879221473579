
import { TranslationMixin } from '~/mixins'

export default {
  name: 'CardWave',
  props: {
    attrTo: {
      type: String
    },
    isButton: {
      type: Boolean,
      default: false
    },
    item: {
      type: Object,
      required: true,
      image: {
        type: Object,
        required: true
      },
      image_mobile: {
        type: Object,
        required: false
      }
    }
  },
  mixins: [TranslationMixin],
  computed: {
    translatedName() {
      const currentTranslatedName = this.getTranslation(this.item.translations)
      return currentTranslatedName?.name || currentTranslatedName?.headline || ''
    },
    imageObject() {
      if (this.isDesktop) return this.desktopImage

      return this.mobileImage
    },
    isDesktop() {
      return !this.$device.isMobile
    },
    desktopImage() {
      return this.item.image
    },
    mobileImage() {
      return this.item.image_mobile || this.desktopImage
    }
  },
  methods: {
    notifyForCardWaveClick() {
      this.$emit('onCardWaveClick')
    }
  }
}
