
export default {
  name: 'CardWaveGenerator',
  render(createElement) {
    return createElement('div', [this.createCurrentElement(createElement)])
  },
  props: {
    attrTo: {
      type: String
    },
    isButton: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isAButton() {
      return this.$props.isButton || false
    },
    isALink() {
      return this.$props.attrTo || false
    }
  },
  methods: {
    createCurrentElement(createElement) {
      if (this.isALink) {
        return createElement(
          'NuxtLink',
          {
            class: ['card__link'],
            attrs: {
              to: this.attrTo
            }
          },
          this.$slots.default
        )
      } else if (this.isAButton) {
        // hasClickListenerAttached
        return createElement(
          'button',
          {
            class: ['card__link'],
            on: {
              click: this.triggerClickEvent
            }
          },
          this.$slots.default
        )
      } else {
        return this.$slots.default
      }
    },
    triggerClickEvent() {
      this.$emit('onCardWaveClick')
    }
  }
}
